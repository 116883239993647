<!-- eslint-disable -->
<template>
  <v-container
    id="public"
    fluid
    tag="section"
    >
    <v-img
      src="../../assets/nashwoodshouse_edit200.jpg"
      center
    > </v-img>

  </v-container>
</template>
<script>
/* eslint-disable */
  export default {
    name: 'PVView',
    components: {
  },
}
</script>
